<template>
<b-form class="form" @submit.stop.prevent="onSubmit">
  <b-container fluid class="p-0">
    <b-row>
      <b-col cols="12" md="8" offset-md="2">
        <b-form-group :label="`${$t('FORM.NAME')}`" label-for="input-1" label-cols-md="2" label-align-md="right">
          <InputForm
            id="input-1"
            v-model="$v.form.name.$model"
            :state="validateState('name')"
            :placeholder="$t('PLACEHOLDER.NAME')"
            trim
            @input="clearServerError('name')"
            aria-describedby="input-1-feedback"
          />
          <b-form-invalid-feedback id="input-1-feedback">
            <template v-if="serverErrors.name">{{ serverErrors.name[0] }}</template>
            <template v-else>{{ $t('VALIDATION.REQUIRED', {name: $t('FORM.NAME')}) }}</template>
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group :label="`${$t('FORM.ADDRESS')}`" label-for="input-2" label-cols-md="2" label-align-md="right">
          <InputForm
            id="input-2"
            v-model="$v.form.address.$model"
            :state="validateState('name')"
            :placeholder="$t('PLACEHOLDER.ADDRESS')"
            trim
            @input="clearServerError('address')"
            aria-describedby="input-1-feedback"
          />
          <b-form-invalid-feedback id="input-1-feedback">
            <template v-if="serverErrors.address">{{ serverErrors.address[0] }}</template>
            <template v-else>{{ $t('VALIDATION.REQUIRED', {name: $t('FORM.ADDRESS')}) }}</template>
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group :label="`${$t('FORM.PHONE')}`" label-for="input-3" label-cols-md="2" label-align-md="right">
          <InputForm
            id="input-3"
            v-model="$v.form.phone.$model"
            :state="validateState('phone')"
            :placeholder="$t('PLACEHOLDER.PHONE')"
            trim
            @input="clearServerError('phone')"
            aria-describedby="input-1-feedback"
          />
          <b-form-invalid-feedback id="input-1-feedback">
            <template v-if="serverErrors.phone">{{ serverErrors.phone[0] }}</template>
            <template v-else-if="!$v.form.phone.alpha">{{
              $t('VALIDATION.INVALID', {name: $t('FORM.PHONE')})
            }}
            </template>
            <template v-else>{{ $t('VALIDATION.REQUIRED', {name: $t('FORM.PHONE')}) }}</template>
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group :label="`${$t('FORM.WORK_TIME_START')}`" label-for="input-4" label-cols-md="2"
                      label-align-md="right"
        >
          <b-form-timepicker
            v-model="$v.form.scheduleStartTime.$model"
            :state="validateState('scheduleStartTime')"
            @input="clearServerError('scheduleStartTime')"
          />
          <b-form-invalid-feedback id="input-1-feedback">
            <template v-if="serverErrors.scheduleStartTime">{{ serverErrors.scheduleStartTime[0] }}</template>
            <template v-else-if="!$v.form.scheduleStartTime.alpha">{{
              $t('VALIDATION.INVALID', {name: $t('FORM.WORK_TIME_START')})
            }}
            </template>
            <template v-else>{{ $t('VALIDATION.REQUIRED', {name: $t('FORM.WORK_TIME_START')}) }}</template>
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group :label="`${$t('FORM.WORK_TIME_END')}`" label-for="input-5" label-cols-md="2"
                      label-align-md="right"
        >
          <b-form-timepicker
            v-model="$v.form.scheduleEndTime.$model"
            :state="validateState('scheduleEndTime')"
            @input="clearServerError('scheduleEndTime')"
          />
          <b-form-invalid-feedback id="input-1-feedback">
            <template v-if="serverErrors.scheduleEndTime">{{ serverErrors.scheduleEndTime[0] }}</template>
            <template v-else-if="!$v.form.scheduleEndTime.alpha">
              {{ $t('VALIDATION.INVALID', {name: $t('FORM.WORK_TIME_END')}) }}
            </template>
            <template v-else>{{ $t('VALIDATION.REQUIRED', {name: $t('FORM.WORK_TIME_END')}) }}</template>
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label-cols-md="2" label-align-md="right">
          <p>{{ $t('BRANCHES.INFO') }}</p>
        </b-form-group>
        <b-form-group label-cols-md="2" label-align-md="right">
          <b-form-checkbox
            switch
            id="checkbox-1"
            v-model="$v.form.active.$model"
          >
            {{ $t('PLACEHOLDER.ACTIVE') }}
          </b-form-checkbox>
        </b-form-group>
        <div class="text-right">
          <b-button :disabled="$loading || $v.form.$anyError" type="submit" variant="primary" size="sm">
            <b-spinner v-if="$loading" small variant="light" />
            {{ $t('FORM.SUBMIT') }}
          </b-button>
        </div>
      </b-col>
    </b-row>
  </b-container>
</b-form>
</template>

<script>
import { helpers, required } from 'vuelidate/lib/validators'
import Repo from '@/core/repository/company/branchesRepository'
import { validationMixin } from 'vuelidate'
import { TEL } from '../../../helpers/validation'
import serverVuelidate from '../../../mixins/serverVuelidate'
import InputForm from '../../forms-items/input'
import Entity from '../../../model/branch'

export default {
  name: 'BranchForm',
  components: { InputForm },
  mixins: [validationMixin, serverVuelidate],
  props: {
    entity: {
      type: Entity,
      default: () => new Entity(),
    },
  },
  data() {
    return {
      form: new Entity(),
      validations: {
        form: {
          name: { required },
          address: { required },
          phone: {
            required,
            alpha: helpers.regex('alpha', TEL), 
          },
          scheduleStartTime: { required },
          scheduleEndTime: { required },
          active: {},
        },
      },
    }
  },
  watch: {
    entity() {
      Object.assign(this.form, this.entity)
    },
  },
  methods: {
    resetForm() {
      this.form = {
        name: '',
        active: null,
      }
      this.$nextTick(this.$v.$reset)
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },
    onSubmit() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        return
      }
      this.loadingOn()
      this.clearServerErrors()
      Repo.save(Object.assign(new Entity(), this.form))
        .then(this.resetForm)
        .then(this.$router.push({ name: 'companyBranchesIndex' }))
        .then(this.$toastSuccess('TOAST.SUCCESS'))
        .catch((err) => {
          this.$toastDanger('TOAST.ERROR')
          this.seServerErrors(err.response.data)
        })
        .finally(this.loadingOff)
    },
  },
}
</script>

<style scoped>

</style>
