import Vue from 'vue'
import EntityRepository from '@/core/repository/entityRepository'
import Branch from '@/model/branch'
import { typeOf } from '@/helpers/type'

export default new class BranchesRepository extends EntityRepository {
  baseUri() {
    return 'company/branches'
  }

  mapEntity(props, prev = null) {
    if (props == null) {
      return null
    }
    let ent = prev
    if (!typeOf(ent, Branch)) {
      ent = new Branch()
    }
    ent.uuid = props.uuid || ent.uuid
    ent.name = props.name || ent.name
    ent.address = props.address || ent.address
    ent.phone = props.phone || ent.phone
    ent.scheduleStartTime = props.scheduleStartTime ? Vue.prototype.$moment(props.scheduleStartTime).format('HH:mm:ss') : ent.scheduleStartTime
    ent.scheduleEndTime = props.scheduleEndTime ? Vue.prototype.$moment(props.scheduleEndTime).format('HH:mm:ss') : ent.scheduleEndTime
    ent.active = props.active || ent.active

    return ent
  }
}()
