import Entity from './entity'

export default class Branch extends Entity {
  /** @type String */
  uuid
  /** @type String */
  name
  /** @type String */
  address
  /** @type String */
  phone
  /** @type String */
  scheduleStartTime
  /** @type String */
  scheduleEndTime
  /** @type Boolean */
  active

  persistentProps() {
    return {
      name: this.name,
      address: this.address,
      phone: this.phone,
      scheduleStartTime: this.scheduleStartTime,
      scheduleEndTime: this.scheduleEndTime,
      active: this.active,
    }
  }
}
